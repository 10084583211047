import BaseRequest from '../BaseRequest'
// import qs from "qs";

export default class DoctorRequest extends BaseRequest {
  rejectConferenceRegister (id, data) {
    return this.post(`go/conference/conference_registrants/reject/${id}`, data)
  }
  getListConferenceReport (params) {
    return this.get(`go/conference/conference_submit_papes`, params)
  }
  importConferenceSpeakersFromExcelFile (params) {
    return this.post(`go/conference/faculty-import`, params)
  }
  importConferenceReportsFromExcelFile ({ conf_id, params }) {
    return this.post(
      `go/conference/submit-papers-import?conf_id=${conf_id}`,
      params
    )
  }
  importConferenceGuestsFromExcelFile ({ conf_id, params }) {
    return this.post(
      `go/conference/conference_registrants/import-offline-attendee?conf_id=${conf_id}`,
      params
    )
  }
  getConferenceReportDetail (id) {
    return this.get(`go/conference/conference_submit_papes/${id} `)
  }
  updateConferenceReport (id, params) {
    return this.put(`go/conference/conference_submit_papes/${id}`, params)
  }
  getAllConferenceRegistrants (params) {
    return this.get(`go/conference/conference_registrants`, params)
  }
  getConferenceCircleMembers (id, params) {
    return this.get(`go/conference/conference_circle/${id}/members`, params)
  }
  getAllConferenceGroups (params) {
    return this.get(`go/conference/conference_groups`, params)
  }
  getAllConferenceCircles (params) {
    return this.get(`go/conference/conference_circle`, params)
  }
  getConferenceSessionDetail (id) {
    return this.get(`go/conference/schedules/${id}`)
  }
  getSearchSessions (params) {
    return this.get(`go/conference/conference_session`, params)
  }
  createConferenceMemberInfo (data) {
    return this.post(`go/conference/conference_registrants`, data)
  }
  updateConferenceMemberInfo (id, params) {
    return this.put(`go/conference/conference_registrants/${id}`, params)
  }
  createSpeaker (params) {
    return this.post('go/conference/conference_registrants', params)
  }
  getConferenceInfo (id) {
    return this.get(`go/conference/${id}`)
  }
  resetPwd (id, params) {
    return this.post(
      `go/conference/conference_registrants/reset-password/${id}`,
      params
    )
  }
  getConferenceCirclePublic (code) {
    return this.get(`go/conference/conference_circle_public/${code}`)
  }
  getConferenceCircleDetailByCode (code) {
    return this.get(`go/conference/conference_circle/circle_id/${code}`)
  }
  getListConferences (params) {
    return this.get(`go/conference`, params)
  }
}
